import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "design-systems"
    }}>{`Design systems`}</h1>
    <p>{`Using a design system lets you split designing your app from building your app.`}</p>
    <p>{`You get:`}</p>
    <ul>
      <li parentName="ul">{`basic building blocks for your UI`}</li>
      <li parentName="ul">{`a flexible interface to customize components`}</li>
      <li parentName="ul">{`theming support for styles`}</li>
      <li parentName="ul">{`and your app looks great`}</li>
    </ul>
    <p>{`Even if like me you can't design your way out of a wet paper bag.`}</p>
    <p>{`Ideally a designer could help you create the core building blocks, then you can work off of sketches on a paper napkin. UI always looks good.`}</p>
    <p>{`Without a designer, you can lean on open-source styles and color schemes.`}</p>
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://github.com/system-ui/theme-ui"
      }}>{`Theme UI`}</a>{` because of its great balance between flexibility and constraints. Other popular choices include `}<a parentName="p" {...{
        "href": "https://material-ui.com/"
      }}>{`Material UI`}</a>{` and `}<a parentName="p" {...{
        "href": "https://tailwindui.com/"
      }}>{`Tailwind UI`}</a>{`.`}</p>
    <h2 {...{
      "id": "themeui-with-gatsby"
    }}>{`ThemeUI with Gatsby`}</h2>
    <p>{`Integrating ThemeUI with Gatsby is a matter of installing a plugin and choosing a theme preset.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/jamstack-workshop-exercises"
      }}>{`exercises repository`}</a>{` and go into the `}<inlineCode parentName="p">{`exercise-1/gatsby`}</inlineCode>{` directory. You'll find a Gatsby project created with the default starter.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`theme-ui`}</inlineCode>{`, `}<inlineCode parentName="p">{`gatsby-plugin-theme-ui`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@theme-ui/presets`}</inlineCode>{`.`}</p>
    <p>{`Add the `}<inlineCode parentName="p">{`gatsby-plugin-theme-ui`}</inlineCode>{` plugin to `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`.`}</p>
    <p>{`Choose a preset from `}<a parentName="p" {...{
        "href": "https://theme-ui.com/packages/presets/"
      }}>{`@theme-ui/presets`}</a>{` and enable it by shadowing the ThemeUI theme from the plugin.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/gatsby-plugin-theme-ui/index.js

import { funk } from "@theme-ui/presets"
export default {
  ...funk,
}
`}</code></pre>
    <p>{`Make sure you remove the CSS import from `}<inlineCode parentName="p">{`layout.js`}</inlineCode>{` to see the full effect.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`Find the final result in the `}<inlineCode parentName="p">{`exercise-1-solution`}</inlineCode>{` branch.`}</p>
    <p>{`I used an unofficial `}<a parentName="p" {...{
        "href": "https://github.com/beerose/theme-ui-sketchy"
      }}>{`sketchy preset`}</a>{` because it looks cool ✌️`}</p>
    <h2 {...{
      "id": "themeui-with-nextjs"
    }}>{`ThemeUI with NextJS`}</h2>
    <p>{`There's no ThemeUI plugin for NextJS and that's okay, integration is a matter of wrapping the app in a ThemeProvider.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/jamstack-workshop-exercises"
      }}>{`exercises repository`}</a>{` and go into the `}<inlineCode parentName="p">{`exercise-1/nextjs`}</inlineCode>{` directory. You'll find a default NextJS project.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`theme-ui`}</inlineCode>{`, and `}<inlineCode parentName="p">{`@theme-ui/presets`}</inlineCode>{`.`}</p>
    <p>{`Enable ThemeUI by wrapping the `}<inlineCode parentName="p">{`<Component>`}</inlineCode>{` in NextJS's `}<inlineCode parentName="p">{`_app.js`}</inlineCode>{` file.`}</p>
    <p>{`Make sure you remove default styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/_app.js

function MyApp({ Component, pageProps }) {
  return (
    <ThemeProvider theme={funk}>
      <Component {...pageProps} />
    </ThemeProvider>
  )
}
`}</code></pre>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p>{`Find the final result in the `}<inlineCode parentName="p">{`exercise-1-solution`}</inlineCode>{` branch.`}</p>
    <p>{`I used an unofficial `}<a parentName="p" {...{
        "href": "https://github.com/beerose/theme-ui-sketchy"
      }}>{`sketchy preset`}</a>{` because it looks cool ✌️`}</p>
    <h2 {...{
      "id": "build-a-theme-switcher"
    }}>{`Build a theme switcher`}</h2>
    <p>{`A popular feature on many websites is Dark Theme. It's a great way to showcase how theming and design systems work together.`}</p>
    <p>{`ThemeUI supports `}<a parentName="p" {...{
        "href": "https://theme-ui.com/color-modes"
      }}>{`color modes`}</a>{` out of the box. You get a helpful `}<inlineCode parentName="p">{`useColorMode`}</inlineCode>{` hook.`}</p>
    <p>{`Presets typically don't support color modes so you'll have to extend the theme. You can do this with creative copy-pasting from presets.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Choose Gatsby or NextJS from the previous exercise. Both follow the same core approach.`}</p>
    <p>{`Add a button on the homepage. Make it cycle through an array of modes.`}</p>
    <p>{`Pseudocode 👇`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const modes = ["light", "dark", "deep"]

const [mode, setMode] = useColorMode()

const i = (modes.indexOf(mode) + 1) % modes.length
setMode(modes[i])
`}</code></pre>
    <p>{`Use the `}<inlineCode parentName="p">{`<Button>`}</inlineCode>{` component from ThemeUI.`}</p>
    <p>{`Extend your theme definition like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// sketchy is the base preset
theme = {
  ...sketchy,
  colors: {
    ...sketchy.colors,
    modes: {
      light: {
        ...sketchy.colors,
      },
      dark: {
        text: "#fff",
        background: "#000",
        copyBackground: "hsl(167, 6%, 42%)",
        primary: "#0ff",
        secondary: "#b0f",
        accent: "#f0b",
        muted: "#111",
        gray: "#999",
        lightgray: "#444",
      },
      deep: {
        text: "hsl(210, 50%, 96%)",
        background: "hsl(230, 25%, 18%)",
        primary: "hsl(260, 100%, 80%)",
        secondary: "hsl(290, 100%, 80%)",
        highlight: "hsl(260, 20%, 40%)",
        purple: "hsl(290, 100%, 80%)",
        muted: "hsla(230, 20%, 0%, 20%)",
        gray: "hsl(210, 50%, 60%)",
      },
    },
  },
}
`}</code></pre>
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p>{`Find the final result in the `}<inlineCode parentName="p">{`exercise-1-solution`}</inlineCode>{` branch.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      